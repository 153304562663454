import axios from '../../helpers/api.service';

const state = {
    all: {},
    topic: {},
    computedDocuments: {},
    topicsDocuments: {},
    topicDocuments: {},

    topicsCount: Number,
    topicCount: Number,
    computedCount: Number,

    filterBucket: {},
    topicFilterBucket: {},
    computedFilterBucket: {},

    topicData: {},
    topicAnalysis: {}
};

const actions = {
    async getTopic({commit}, id) {
        const response = await axios().get('/topics/topic/' + id);
        commit('SET_TOPIC', response.data);
    },
    async getTopics({commit}, id) {
        const response = await axios().get('/topics/' + id);
        commit('SET_TOPICS', response.data);
    },
    async deleteTopic({dispatch}, data) {
        await axios().delete('/topics/topic/' + data.id)
        dispatch('getTopics', data.caseId);
    },
    async postTopic({dispatch}, data) {
        await axios().post('/topics', data);
        dispatch('getTopics', data.caseId);
    },
    async editTopic({dispatch}, data) {
        await axios().put('/topics', data);
        dispatch('getTopics', data.caseId);
    },
    async getTopicsDocuments({commit}, data) {
        const response = await axios().post('/topics/documents', data);
        commit('SET_TOPICS_DOCS', response.data);
    },
    async getComputedDocuments({commit}, data) {
        const response = await axios().post('/topics/topic/computed', data);
        commit('SET_COMPUTED', response.data);
    },
    async getComputedDocumentsCount({commit}, data) {
        const response = await axios().post('/topics/topic/computed-count', data);
        commit('SET_COMPUTED_COUNT', response.data);
    },
    async getTopicDocuments({commit}, data) {
        const response = await axios().post('/topics/topic/documents', data);
        commit('SET_TOPIC_DOCS', response.data);
    },
    async getEventTopicDocuments({commit}, data) {
        const response = await axios().post('/topics/event-topic/documents/', data);
        commit('SET_COMPUTED', response.data);
    },
    async getSelectedTopicDocuments({commit}, data) {
        const response = await axios().post('/topics/selected-topic/documents/', data);
        commit('SET_COMPUTED', response.data);
    },
    async getTopicData({commit}, id) {
        const response = await axios().get('/topics/topic-data/'+ id);
        commit('SET_TOPIC_DATA', response.data);
    },
    async runAnalysis(_, data) {
        await axios().post('/topics/analysis', data);
    },
    async getTopicAnalysis({commit}, id) {
        const response = await axios().get('/cogentic/topic-analysis/' + id);
        commit('SET_TOPIC_ANALYSIS', response.data);
    },
};

const mutations = {
    SET_TOPIC(state, topic) {
        state.topic = topic;
    },
    SET_TOPICS(state, cases) {
        state.all = {items: cases};
    },
    SET_TOPIC_DATA(state, data) {
        state.topicData = data;
    },
    SET_TOPICS_DOCS(state, data) {
        state.topicsDocuments = data.documents;
        state.topicsCount = data.totalHits;
        state.filterBucket = data.bucket;
    },
    SET_COMPUTED(state, data) {
        state.computedDocuments = data.documents;
        state.computedCount = data.totalHits;
        state.computedFilterBucket = data.bucket;
    },
    SET_COMPUTED_COUNT(state, data) {
        state.computedCount = data;
    },
    SET_TOPIC_DOCS(state, data) {
        state.topicDocuments = data.documents;
        state.topicCount = data.totalHits;
        state.topicFilterBucket = data.bucket;
    },
    SET_TOPIC_ANALYSIS(state, data) {
        state.topicAnalysis = data;
    }
};

export const topics = {
    namespaced: true,
    state,
    actions,
    mutations
};