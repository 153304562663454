import axios from '../../helpers/api.service';

const state = {
	hypotheses: {},
	hypothesis: {},
	report: {},
	reportType: {},
	elementAnalysis: {},
	summary: '',
	qa: {},
	answer: {}
};

const actions = {
	async getHypotheses({commit}, id) {
		const response = await axios().get('/cogentic/hypotheses/' + id);
		commit('SET_HYPOTHESES', response.data);
	},
	async getHypothesis({commit}, id) {
		const response = await axios().get('/cogentic/hypothesis/' + id);
		commit('SET_HYPOTHESIS', response.data);
	},
	async createHypothesis({dispatch}, data) {
		await axios().post('/cogentic/hypothesis', data);
		dispatch('getAllHypothesis');
	},
	async deleteHypothesis({dispatch}, data) {
		await axios().delete('/cogentic/hypothesis' + data.id);
		dispatch('getAllHypothesis');
	},
	async getReport({commit}, id) {
		const response = await axios().get('/cogentic/report/' + id);
		commit('SET_REPORT', response.data);
	},
	async getReportType({commit}, data) {
		const response = await axios().get('/cogentic/report/type/' + data.id + '/' + data.type);
		commit('SET_REPORT_TYPE', response.data);
	},
	async getElementAnalysisType({commit}, data) {
		const response = await axios().get('/cogentic/element/type/' + data.id + '/' + data.type);
		commit('SET_ELEMENT_ANALYSIS', response.data);
	},
	async getSummary({commit}, data) {
		const response = await axios().get('/cogentic/summary/' + data.caseId + '/' + data.documentId);
		commit('SET_SUMMARY', response.data);
	},
	async getQA({commit}, data) {
		const response = await axios().get('/cogentic/qa/' + data.caseId + '/' + data.elementId);
		commit('SET_QA', response.data);
	},
	async getAnswer({commit}, id) {
		const response = await axios().get('/cogentic/answer/' + id);
		commit('SET_ANSWER', response.data);
	}
}

const mutations = {
	SET_HYPOTHESES(state, data) {
		state.hypotheses =  data;
	},
	SET_HYPOTHESIS(state, data) {
		state.hypothesis = data;
	},
	SET_REPORT(state, data) {
		console.log(data)
		state.report = data;
	},
	SET_REPORT_TYPE(state, data) {
		state.reportType = data;
	},
	SET_ELEMENT_ANALYSIS(state, data) {
		state.elementAnalysis = data;
	},
	SET_SUMMARY(state, data) {
		state.summary = data;
	},
	SET_QA(state, data) {
		state.qa = data;
	},
	SET_ANSWER(state, data) {
		state.answer = data;
	}
};

export const cogentic = {
	namespaced: true,
	state,
	actions,
	mutations
};