import axios from '../../helpers/api.service';

const state = {
    reply: {},
    chatData: {},
    chatDataArray: [],
    chatHistory: {}
};

const actions = {
    async sendMessage({commit}, data) {
        const response = await axios().post('/agent/message', data);
        commit('SET_REPLY', response.data);
    },
    async getChatHistory({ commit }, data) {
        const response = await axios().get('/chat/'+ data.caseId + '/' + data.userId);
        commit('SET_CHAT_HISTORY', response.data);
    },
    setChatData({ commit }, chatData) {
        commit('SET_CHAT_DATA', chatData);
    },
    setChatDataArray({ commit }, chatDataArray) {
        commit('SET_CHAT_DATA_ARRAY', chatDataArray);
    },
    clearChatData({ commit }) {
        commit('CLEAR_CHAT_DATA');
    },
    async getChatHistoryAnswer({ dispatch, commit }, data) {
        commit('CLEAR_CHAT_DATA');
        const response = await axios().get('/chat/chat-documents/' + data.id + '/' + data.caseId);
        for (let i = 0; i < response.data.length; i++) {
            const reply = response.data[i].chatMessage;
            const data = response.data[i];
            const associatedDocumentIds = reply.associatedDocumentIds.join(", ");
            const chatDataArray = {question: reply.question, answer: reply.answer, longAnswer: reply.longAnswer, excerpt: reply.excerpt, associatedDocumentIds: associatedDocumentIds};
            dispatch('setChatData', {id: i, data: {reply: reply, data: data}});
            dispatch('setChatDataArray', chatDataArray);
        }
    },
    async saveChatHistory({ dispatch }, data) {
        data.chatDataArray = state.chatDataArray;
        await axios().post('/chat', data);
        dispatch('getChatHistory', { caseId: data.caseId, userId: data.userId });
    },
    async deleteChatHistory({dispatch}, data) {
        await axios().delete('/chat/' + data.id)
        dispatch('getChatHistory', { caseId: data.caseId, userId: data.userId });
    },

}

const mutations = {
    SET_REPLY(state, data) {
        state.reply = data;
    },
    SET_CHAT_DATA(state, data) {
        if (state.chatData.hasOwnProperty(data.id)) {
            state.chatData[data.id].push(data.data);
        } else {
            state.chatData[data.id] = [data.data];
        }
    },
    SET_CHAT_DATA_ARRAY(state, data) {
        state.chatDataArray.push(data);
    },
    SET_CHAT_HISTORY(state, data) {
        state.chatHistory = data;
    },
    CLEAR_CHAT_DATA(state) {
        state.chatDataArray = [];
        state.chatData = {};
        state.reply = {};
    }
}

export const chatbot = {
    namespaced: true,
    state,
    actions,
    mutations
};