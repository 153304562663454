import axios from '../../helpers/api.service';

const state = {
    all: {},
    caseCards: {},
    case: {},
    caseNotes: [],
    caseStory: [],
    caseCustodian: {}
};

const actions = {
    async loadCases({commit}) {
        const response = await axios().get('/cases');
        commit('SET_CASES', response.data);
    },
    async getCaseCards({commit}, id) {
        const response = await axios().get('/cases/casecards/' + id);
        commit('SET_CASE_CARDS', response.data);
    },
    async getAllCaseCards({commit}) {
        const response = await axios().get('/cases/allcasecards');
        commit('SET_CASE_CARDS', response.data);
    },
    async deleteCase({dispatch}, id) {
        await axios().delete('/cases/case/' + id)
        dispatch('loadCases');
    },
    async createCase({dispatch}, data) {
        await axios().post('/cases', data);
        dispatch('loadCases');
    },
    async editCase({dispatch}, data) {
        await axios().put('/cases', data);
        dispatch('loadCases');
        dispatch('getCase', data.caseId)
    },
    async getCase({commit}, id) {
        const response = await axios().get('/cases/case/' + id);
        commit('SET_CASE', response.data);
    },
    // Case Story ----------------------------------------------------------------------------------------------------------------------------------//

    async getCaseStories({commit}, id) {
        const response = await axios().get('/cases/case/story/' + id);
        commit('SET_CASE_STORIES', response.data);
    },
    async createCaseStory({dispatch}, data) {
        await axios().post('/cases/case/story', data);
        dispatch('getCaseStories', data.caseId);
    },
    async deleteCaseStory({dispatch}, data) {
        await axios().delete('/cases/case/story/' + data.id)
        dispatch('getCaseStories', data.caseId);
    },

    // Case Notes ----------------------------------------------------------------------------------------------------------------------------------//

    async getCaseNotes({commit}, id) {
        const response = await axios().get('/cases/case/notes/' + id);
        commit('SET_CASE_NOTES', response.data);
    },
    async editCaseNote({dispatch}, data) {
        await axios().put('/cases/case/notes', data);
        dispatch('getCaseNotes', data.caseId)
    },
    async createCaseNote({dispatch}, data) {
        await axios().post('/cases/case/notes', data);
        dispatch('getCaseNotes', data.caseId)
    },
    async deleteCaseNote({dispatch}, data) {
        await axios().delete('/cases/case/notes/' + data.id)
        dispatch('getCaseNotes', data.caseId);
    },
    // Case Custodian ------------------------------------------------------------------------------------------------------------------------------//

    async getCaseCustodian({commit}, id) {
        const response = await axios().get('/cases/case/custodian/' + id);
        commit('SET_CASE_CUSTODIAN', response.data);
    }
};

const mutations = {
    SET_CASES(state, cases) {
        state.all = {items: cases};
    },
    SET_CASE_CARDS(state, caseCards) {
        state.caseCards = {items: caseCards};
    },
    SET_CASE(state, data) {
        state.case = data;
    },
    SET_CASE_NOTES(state, data) {
        state.caseNotes = data;
    },
    SET_CASE_STORIES(state, data) {
        state.caseStory = data;
    },
    SET_CASE_CUSTODIAN(state, data) {
        state.caseCustodian = {items: data};
    }
};

export const cases = {
    namespaced: true,
    state,
    actions,
    mutations
};