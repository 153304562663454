import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '@/helpers/globals'
import Layout from '@/layouts/Layout'
import LayoutBlank from '@/layouts/LayoutBlank'
import AuthService from '@/service/security/auth.service'
import Error from '@/security/Error';


Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    base: '/',
    mode: 'history',
    routes: [{
        path: '/',
        component: Layout,
        children: [{
            path: 'home',
            component: () => import('@/modules/Home')
        }, {
            path: 'users',
            component: () => import('@/profile/users/Users'),
        }, {
            path: 'cases',
            component: () => import('@/components/cases/Cases')
        }, {
            path: 'clients',
            component: () => import('@/profile/clients/Clients')
        }, {
            path: 'dashboard',
            component: () => import('@/modules/Dashboard')
        }, {
            path: 'events',
            component: () => import('@/components/event/Events')
        }, {
            path: 'dataload',
            component: () => import('@/components/dataload/DataLoadList')
        }, {
            path: 'dataloadform',
            component: () => import('@/components/dataload/DataLoadForm')
        }, {
            path: 'search',
            component: () => import('@/modules/search/Search')
        }, {
            path: 'browse',
            component: () => import('@/modules/search/Browse')
        }, {
            path: 'tasks',
            component: () => import('@/components/tasks/Tasks')
        }, {
            path: 'storyline',
            component: () => import('@/modules/storyline/StoryLine'),
        }, {
            path: 'network',
            component: () => import('@/modules/network/Network'),
        }, {
            path: 'topics',
            component: () => import('@/modules/topics/TopicExplorer'),
        }, {
            path: 'timemachine',
            component: () => import('@/modules/timemachine/TimeMachine'),
        }, {
            path: 'briefcases',
            component: () => import('@/modules/briefcases/Briefcases'),
        }, {
            path: 'briefcase',
            component: () => import('@/modules/briefcases/Briefcase'),
        }, {
            path: 'profile',
            component: () => import('@/profile/users/Profile'),
        }, {
            path: 'production',
            component: () => import('@/modules/production/ProductionList.vue'),
        }, {
            path: 'chatbot',
            component: () => import('@/modules/timemachine/Chatbot.vue'),
        }, {
            path: 'admin',
            component: () => import('@/modules/admin/Administrator'),
        }, {
            path: 'review',
            component: () => import('@/modules/autonomous/review/Reviews.vue'),
        }, {
            path: 'review-dashboard',
            component: () => import('@/modules/autonomous/review/ReviewDashboard.vue'),
        }, {
            path: 'review-protocol',
            component: () => import('@/modules/autonomous/review/ReviewProtocol.vue'),
        }, {
            path: 'reviewed',
            component: () => import('@/modules/ar/request/RequestTable.vue'),
        }, {
            path: 'relevance-review',
            component: () => import('@/modules/autonomous/table/ResponsiveTable.vue'),
        }, {
            path: 'responsive-review',
            component: () => import('@/modules/autonomous/table/ResponsiveTable.vue'),
        }, {
            path: 'responsive-viewer',
            component: () => import('@/modules/autonomous/viewer/ResponsiveViewer.vue'),
        }, {
            path: 'privilege-review',
            component: () => import('@/modules/autonomous/table/PrivilegeTable.vue'),
        }, {
            path: 'privilege-viewer',
            component: () => import('@/modules/autonomous/viewer/PrivilegeViewer.vue'),
        }, {
            path: 'emmett',
            component: () => import('@/modules/emmett/EmmettBot.vue'),
        }, {
            path: 'cogentic',
            component: () => import('@/modules/cogentic/Cogentic.vue'),
        }, {
            path: 'verify',
            component: () => import('@/modules/ar/viewer/HumanReview.vue'),
        }]
    },
        {
            path: '/',
            component: LayoutBlank,
            children: [{
                path: 'login',
                component: () => import('@/security/Login'),
            }]
        },
        {path: '*', component: Error}
    ]
})

router.afterEach(() => {
    // On small screens collapse sidenav
    if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
    }

    // Scroll to top of the page
    globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
    // Set loading state
    document.body.classList.add('app-loading')
    setTimeout(() => next(), 10)
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (to.fullPath === '/') {
        next('/login');
    }

    if (authRequired) {
        AuthService.validate()
    }

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch((err) => {
        if (Router.isNavigationFailure(err)) {
            return err
        }

        return Promise.reject(err)
    })
}

export default router