<template>
    <b-navbar :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x bg-primary-dark navbar-dark" toggleable="lg">
        <b-navbar-brand class="app-brand logo d-lg-none py-0 mr-2">
      <span class="app-brand-logo">
        <img src="/img/logo.png" width="35px">
      </span>
            <span class="app-brand-text logo font-weight-normal ml-2"></span>
        </b-navbar-brand>

        <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

        <div class="breadcrumb-list">
            <span class="truncate">{{ $store.state.navigation.breadcrumb }} {{ caseTitle }}</span>
        </div>

        <b-collapse id="app-layout-navbar" is-nav>
            <!-- Divider -->
            <hr class="d-lg-none w-100 my-2">

            <b-navbar-nav class="align-items-lg-center ml-auto">

                <a :href="'/dashboard'" class="sidenav-link" title="Case Dashboard" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-menu-case-icon ion ion-ios-speedometer"></i>
                </a>

                <a :href="'/search'" class="sidenav-link" title="Search" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-menu-case-icon ion ion-ios-search"></i>
                </a>

                <a :href="'/browse'" class="sidenav-link" title="Browse" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-menu-case-icon ion ion-ios-filing"></i>
                </a>

                <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1" v-if="isCase">|</div>

                <a :href="'/storyline'" class="sidenav-link" title="Storyline" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-module-icon ion ion-md-bookmarks"></i>
                </a>

                <a :href="'/timemachine'" class="sidenav-link" title="Time Machine" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-module-icon ion ion-ios-timer"></i>
                </a>

                <a :href="'/topics'" class="sidenav-link" title="Topic Explorer" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-module-icon ion ion-ios-disc"></i>
                </a>

                <a :href="'/network'" class="sidenav-link" title="Knowledge Browser" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-module-icon ion ion-ios-color-filter"></i>
                </a>

                <a :href="'/emmett'" class="sidenav-link" title="Emmett" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-module-alt-icon fas fa-robot"></i>
                </a>
                <!--
                <a :href="'/cogentic'" class="sidenav-link" title="Cogentic" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-module-alt-icon fas fa-burn"></i>
                </a> -->

                <a :href="'/briefcase'" class="sidenav-link" title="Briefcases" data-placement="top" v-b-tooltip.hover v-if="isCase">
                    <i class="navbar-module-icon ion ion-ios-briefcase"></i>
                </a>

                <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1" v-if="isCase">|</div>

                <a class="sidenav-link" href="/home" title="My Cases" v-b-tooltip.hover>
                    <i class="navbar-menu-icon ion ion-ios-today"></i>
                </a>
                <a v-if="role === 'Admin'" class="sidenav-link" href="/review" title="Autonomous Review" v-b-tooltip.hover>
                    <i class="navbar-menu-icon fab fa-rev"></i>
                </a>

                <a v-if="role === 'Admin'" class="sidenav-link" href="/admin" title="Admin" v-b-tooltip.hover>
                    <i class="navbar-menu-icon ion ion-md-settings"></i>
                </a>


                <!-- Divider -->
                <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

                <b-nav-item-dropdown :right="!isRTL" class="main-navbar-user">
                    <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <div class="circle">{{ profileInitial }}</div>
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
                      $store.state.authentication.profile.firstName
                  }} {{ $store.state.authentication.profile.lastName }}</span>
            </span>
                    </template>

                    <b-dd-item href="/profile"><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
                    <b-dd-divider/>
                    <b-dd-item @click.prevent="logout"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out</b-dd-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
export default {
    name: 'app-layout-navbar',

    props: {
        sidenavToggle: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isCase: false,
            caseId: null,
            caseTitle: null,
            profileInitial: '',
            role: 'Member'
        }
    },
    mounted() {
        this.checkRouter();
        this.caseId = localStorage.getItem('caseId');
        this.role = this.$store.state.authentication.profile.role;
        this.getInitials();

        if (this.caseId) {
            this.isCase = true;
        }
    },
    methods: {
        getLayoutNavbarBg() {
            return this.layoutNavbarBg
        },
        getInitials() {
            let firstname = this.$store.state.authentication.profile.firstName;
            let lastname = this.$store.state.authentication.profile.lastName;
            this.profileInitial = firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase()
        },
        checkRouter() {
            const path = this.$router.currentRoute.path
            const routes = ["/", "/admin", "/home", "/profile", "/review", "/verify", "/privilege", "/reviewed"];
            if (routes.includes(path)) {
                this.caseTitle = null;
            } else {
                this.caseTitle = " : " + localStorage.getItem('caseTitle');
            }
        },
        logout() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }
}
</script>

<style scoped>
.breadcrumb-list {
    font-size: 20px;
    text-transform: capitalize;
    white-space: nowrap;
    color: #ffffff;
}

.breadcrumb-list a {
    color: #ffffff;
    padding: 0 15px;
    margin-left: -12px;
}


.navbar-module-icon {
    color: #355e96;
    font-size: 1.3rem;
    padding-top: 0.2rem;
    padding-right: 0.75rem;
}

.navbar-module-alt-icon {
    color: #355e96;
    font-size: 1.12rem;
    padding-top: 0.2rem;
    padding-right: 0.75rem;
    margin-bottom: 0.37rem;
}

.navbar-menu-icon {
    color: #676767;
    font-size: 1.3rem;
    padding-top: 0.2rem;
    padding-right: 0.75rem;
}

.navbar-menu-case-icon {
    color: #426596;
    font-size: 1.3rem;
    padding-top: 0.2rem;
    padding-right: 0.75rem;
}

.navbar-menu-icon:hover {
    color: #ffffff;
}

.navbar-module-icon:hover {
    color: #ffffff;
}

.navbar-module-alt-icon:hover {
    color: #ffffff;
}

.navbar-menu-case-icon:hover {
    color: #ffffff;
}

.circle {
    height: 30px;
    width: 30px;
    padding-top: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    display: flex;
    background: #694b7a;
}

.nav-link .circle {
    color: #ffffff;
}


</style>
<style>
.dropdown-toggle::after {
    display: none !important;
}
</style>