import axios from '../../helpers/api.service';

const state = {
    coding: {},
    topics: {},
    events: {},
    elements: {},
    notes: {},
    metadata: {},
    status: ''
};

const actions = {
    async getCoding({commit}, documentId) {
        const response = await axios().get('/codings/coding/' + documentId);
        commit('SET_CODING', response.data);
    },
    async deleteDocumentCoding({state}, documentId) {
        await axios().delete('/codings/coding/document/' + documentId);
        state.status = 'reset';
    },
    async resetSignificantCoding({state}, documentId) {
        await axios().delete('/codings/coding-significant/document/' + documentId);
        state.status = 'reset';
    },
    async postResponsiveness({state}, data) {
        await axios().post('/codings/coding/responsiveness', data);
        state.status = 'review';
    },
    async postSignificance({state}, data) {
        await axios().post('/codings/coding/significance', data);
        state.status = 'review';
    },
    async postPrivilege({state}, data) {
        await axios().post('/codings/coding/privilege', data);
        state.status = 'review';
    },
    async postConfidential({state}, data) {
        await axios().post('/codings/coding/confidential', data);
        state.status = 'review';
    },
    // Topics --------------------------------------------------------------------------------------------------------------------------------------//
    async getLinkedTopics({commit}, documentId) {
        const response = await axios().get('/codings/topics/' + documentId);
        commit('SET_TOPICS', response.data);
    },
    async postTopics({state}, data) {
        await axios().post('/codings/topics', data);
        state.status = 'topic-review';
    },
    // Events --------------------------------------------------------------------------------------------------------------------------------------//
    async getLinkedEvents({commit}, documentId) {
        const response = await axios().get('/codings/events/' + documentId);
        commit('SET_EVENTS', response.data);
    },
    async postEvents({state}, data) {
        await axios().post('/codings/events', data);
        state.status = 'event-review';
    },
    // Elements ------------------------------------------------------------------------------------------------------------------------------------//
    async getDocumentElements({commit}, documentId) {
        const response = await axios().get('/codings/elements/' + documentId);
        commit('SET_ELEMENTS', response.data);
    },
    async postDocumentElements({state}, data) {
        await axios().post('/codings/elements', data);
        state.status = 'element-review';
    },
    async editDocumentElements({state}, data) {
        await axios().put('/codings/elements', data);
        state.status = 'element-review';
    },
    // Notes ---------------------------------------------------------------------------------------------------------------------------------------//
    async getNotes({commit}, documentId) {
        const response = await axios().get('/codings/notes/' + documentId);
        commit('SET_NOTES', response.data);
    },
    async postNote({commit}, data) {
        await axios().post('/codings/notes', data);
        const response = await axios().get('/codings/notes/' + data.documentId);
        commit('SET_NOTES', response.data);
        state.status = 'note-review';
    },
    async editNote({commit}, data) {
        await axios().put('/codings/notes', data);
        const response = await axios().get('/codings/notes/' + data.documentId);
        commit('SET_NOTES', response.data);
        state.status = 'note-review';
    },
    async deleteNote({commit}, id) {
        const res = await axios().delete('/codings/notes/' + id);
        const response = await axios().get('/codings/notes/' + res.data);
        commit('SET_NOTES', response.data);
    },
    // Document Metadata ---------------------------------------------------------------------------------------------------------------------------------------//
    async getMetadata({commit}, documentId) {
        const response = await axios().get('/codings/metadata/' + documentId);
        commit('SET_METADATA', response.data);
    },
};

const mutations = {
    SET_CODING(state, coding) {
        state.coding = {items: coding};
    },
    SET_TOPICS(state, topics) {
        state.topics = {items: topics};
    },
    SET_EVENTS(state, events) {
        state.events = {items: events};
    },
    SET_ELEMENTS(state, elements) {
        state.elements = {items: elements};
    },
    SET_NOTES(state, notes) {
        state.notes = {items: notes};
    },
    SET_METADATA(state, metadata) {
        state.metadata = {items: metadata};
    }
};

export const codings = {
    namespaced: true,
    state,
    actions,
    mutations
};
