import axios from '../../helpers/api.service';
import Vue from 'vue'

const state = {
    currentDocuments: {},
    document: {},
    nextDocumentId: '',
    previousDocumentId: '',
    documentSetSize: '',
    documentIndex: '',
    documentCards: {},
    family: {},
    keyDocumentCount: 0,
    importantDocumentCount: 0,
    currentDocumentCount: 0
};

const actions = {
    async getDocument({commit}, id) {
        const response = await axios().get('/documents/document/' + id);
        commit('SET_DOCUMENT', response.data);
    },
    async getFamily({commit}, id) {
        const response = await axios().get('/documents/family/' + id);
        commit('SET_FAMILY', response.data);
    },
    async setCurrentDocuments({commit}, documents) {
        commit('SET_CURRENT', documents);
    },
    async updateSignificance({commit}, data) {
        commit('SET_SIGNIFICANCE', data);
    },
    async updatePrivilege({commit}, data) {
        commit('SET_PRIVILEGE', data);
    },
    async updateTopics({commit}, data) {
        commit('SET_TOPIC', data);
    },
    async updateEvents({commit}, data) {
        commit('SET_EVENT', data);
    },
    async updateEntities({commit}, data) {
        commit('SET_ENTITY', data);
    },
    async updateStatus({commit}, data) {
        commit('SET_STATUS', data);
    },
    async resetData({commit}, data) {
        commit('SET_RESET', data);
    },

    // Document Timeline ---------------------------------------------------------------------------------------------------------------------------//

    async getEventDocuments({commit}, id) {
        const response = await axios().get('/documents/event/documents/' + id);
        commit('SET_DOCUMENT_CARDS', response.data);
    }
};

const mutations = {

    SET_DOCUMENT(state, document) {
        state.document = document;
    },
    SET_FAMILY(state, family) {
        state.family = family;
    },
    SET_DOCUMENT_CARDS(state, documentCards) {
        state.documentCards = {items: documentCards};
    },
    SET_CURRENT(state, data) {
        if (data != null) {
            state.currentDocuments = data;
            state.currentDocumentCount = state.currentDocuments.length;
        }
    },
    SET_SIGNIFICANCE(state, data) {
        const index = state.currentDocuments.findIndex((obj => obj.documentId == data.documentId));
        Vue.set(state.currentDocuments[index], 'significance', data.significance);
        Vue.set(state.currentDocuments[index], 'status', "REVIEWED");
    },
    SET_PRIVILEGE(state, data) {
        const index = state.currentDocuments.findIndex((obj => obj.documentId == data.documentId));
        Vue.set(state.currentDocuments[index], 'privilege', data.privilege);
        Vue.set(state.currentDocuments[index], 'status', "REVIEWED");
    },
    SET_TOPIC(state, data) {
        const index = state.currentDocuments.findIndex((obj => obj.documentId == data.documentId));
        Vue.set(state.currentDocuments[index], 'topics', data.topics);
        Vue.set(state.currentDocuments[index], 'status', "REVIEWED");
    },
    SET_EVENT(state, data) {
        const index = state.currentDocuments.findIndex((obj => obj.documentId == data.documentId));
        Vue.set(state.currentDocuments[index], 'eventId', data.eventId);
        Vue.set(state.currentDocuments[index], 'status', "REVIEWED");
    },
    SET_ENTITY(state, data) {
        const index = state.currentDocuments.findIndex((obj => obj.documentId == data.documentId));
        Vue.set(state.currentDocuments[index], 'documentEntity', data.documentEntity);
        Vue.set(state.currentDocuments[index], 'status', "REVIEWED");
    },
    SET_STATUS(state, data) {
        const index = state.currentDocuments.findIndex((obj => obj.documentId == data.documentId));
        Vue.set(state.currentDocuments[index], 'status', "REVIEWED");
    },
    SET_RESET(state, data) {
        const index = state.currentDocuments.findIndex((obj => obj.documentId == data.documentId));
        Vue.set(state.currentDocuments[index], 'significance', null);
        Vue.set(state.currentDocuments[index], 'privilege', null);
        Vue.set(state.currentDocuments[index], 'confidential', null);
    }
};

export const documents = {
    namespaced: true,
    state,
    actions,
    mutations
};