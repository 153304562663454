import Vue from 'vue';
import Vuex from 'vuex';

import {authentication} from './security/authentication';
import {autonomous} from './module/autonomous';
import {briefcases} from './component/briefcases';
import {cases} from './component/cases';
import {chatbot} from "@/service/component/chatbot";
import {codings} from './component/codings';
import {cogentic} from './module/cogentic';
import {clients} from './profile/clients';
import {documents} from './component/documents';
import {dataloads} from "@/service/component/dataload";
import {entities} from './module/entities';
import {events} from './component/events';
import {navigation} from './security/navigation';
import {network} from './module/network';
import {productions} from "./component/production";
import {tasks} from './component/tasks';
import {teams} from './profile/teams';
import {topics} from './module/topics';
import {reviews} from './module/review';
import {requests} from './module/request';
import {search} from './module/search';
import {socialmedia} from './module/socialmedia';
import {storyline} from './module/storyline';
import {users} from './profile/users';
import {dashboard} from "@/service/module/dashboard";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        authentication,
        autonomous,
        briefcases,
        cases,
        chatbot,
        codings,
        cogentic,
        clients,
        dashboard,
        dataloads,
        documents,
        entities,
        events,
        navigation,
        network,
        productions,
        tasks,
        teams,
        topics,
        reviews,
        requests,
        search,
        socialmedia,
        storyline,
        users
    }
});